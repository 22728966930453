body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

body.embed, body.embed #root {
	/* background-color:rgba(0,0,0,0.87); */
	background-color:#212121;
	padding-bottom:48px;
}

body.embed .sectionCard {
	border-radius: 0;
	margin: 0;
	max-width: 100%;
	box-shadow: none;
	overflow-x: hidden;
}

a.embedLink, a.embedLink:visited, a.embedLink:active, a.embedLink:focus {
	color:#ccc;
	cursor:pointer;
}
a.embedLink:hover {
	color:#fff;
}

.nav-date-field {
	background-color: transparent;
	color: #ccc;
	border:none;
	border-bottom: solid 1px #ccc;
}

